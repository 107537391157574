import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6e38ce4c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "wrapper-input-file" }
const _hoisted_2 = ["accept"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", {
      class: _normalizeClass($props.viewClass)
    }, [
      _createTextVNode(_toDisplayString($setup.props.text), 1),
      _createElementVNode("input", {
        class: "input-file-input",
        type: "file",
        onChange: $setup.onChange,
        accept: $setup.props.accept
      }, null, 40, _hoisted_2)
    ], 2)
  ]))
}